<template>
  <div class="server-container">
    <div class="title">提供专业数字政务服务</div>
    <div class="flex flex-between">
      <img src="~@/assets/index/server-img1.png" alt="">
      <img src="~@/assets/index/server-img2.png" alt="">
      <img src="~@/assets/index/server-img3.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.server-container {
  width: 1920px;
  height: 671px;
  background: url("https://imagepub.chinau8.cn/tianhua_jinhua/mj-website/server-bg.png") no-repeat;
  background-size: contain;
  box-sizing: border-box;
  padding: 64px 338px;

  .title {
    font-size: 42px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 49px;
    margin-bottom: 94px;
  }

  img {
    width: 285px;
    height: 346px;
  }

  img:hover {
    transform: scale(1.1);
  }
}
</style>
