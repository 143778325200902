<template>
  <div class="container">
    <div class="title">流程梳理</div>
    <div class="list flex flex-between">
      <div class="item" v-for="(item,index) in list" @click="itemClick(item)">
        <div class="item-name">
          <img :src="item.bg" alt=""/>
          <div>{{ item.name }}</div>
        </div>
        <div class="children">
          <div v-for="(obj,i) in item.children">
            <div class="children-title">{{ obj.title }}</div>
            <div class="children-content" :class="index == 2 ? 'mb30' : ''">{{ obj.content }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          name: "企业信息库",
          bg: require("@/assets/process/process-bg1.png"),
          value: 0,
          children: [
            {title: "收集数据、指标", content: "对不同归口的基础数据，进行采集、梳理与校验整合"},
            {title: "选取参评企业", content: "根据已核验的指标信息，具体依据，初步选取参评企业入库"},
            {title: "土地调查调整", content: "归拢用地数据、租赁情况，核实并修正信息，确定参评企业名单"},
          ]
        },
        {
          name: "年度评价名单",
          bg: require("@/assets/process/process-bg2.png"),
          value: 1,
          children: [
            {title: "企业合并评价", content: "企业查收核对基础参评信息、提交合并申请及特殊事项申请"},
            {title: "企业名单、评价指标导入", content: "整合参评企业名单及具体评价指标，确认评价主体"},
            {title: "企业核实确认", content: "企业查收详细的年度参评信息、核验确认后生成标签信息"},
          ]
        },
        {
          name: "综合评价计算",
          bg: require("@/assets/process/process-bg3.png"),
          value: 2,
          children: [
            {title: "参评数据维护", content: "可对评价指标信息进行分类维护，独立加减分操作"},
            {title: "亩均效益计算", content: "根据评价指标、公式、权重、得分限制，一键便捷计算"},
            {title: "亩均绩效展示", content: "系统按照得分、规上规下、行业类别归档排序，生成年度评价结果"},
            {title: "企业核实确认", content: "企业查收核对年度终评结果信息"},
          ]
        },
        {
          name: "年度绩效统计",
          bg: require("@/assets/process/process-bg4.png"),
          value: 3,
          children: [
            {title: "年度数据报表", content: "支持自定义配置筛选项、导出项，生成年度统计报表"},
            {title: "规则算法备份复用", content: "备份评价指标、公式算法，复用历年数据，汇入分类预演模型"},
            {title: "产业全景图", content: "按地理区域、产业行业等对经济运行状况、企业绩效信息等进行监测剖析"},
          ]
        },
        {
          name: "综合分析运用",
          bg: require("@/assets/process/process-bg5.png"),
          value: 4,
          children: [
            {title: "经济月报、季报", content: "依托评价工作数据资源，反映实况趋势，辅助决策研判"},
            {title: "专题分析报告", content: "支持不同维度、多层次的综合经济信息分析设置，发掘潜力引导调整"},
            {
              title: "工业用地监测",
              content: "抽取平台关键指标，构建用地监测模型，对区域概况、产值能耗、承租信息等动态呈现"
            },
          ]
        },
      ],
    }
  },
  methods: {
    itemClick(item) {
      this.$router.push({path: `/product?active=${item.value}`,})
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 1920px;
  height: 1080px;
  box-sizing: border-box;
  background: #F5F7FC;
  padding: 120px 206px 215px;
  position: relative;

  .title {
    font-size: 52px;
    font-weight: bold;
    color: #333333;
    line-height: 61px;
    margin-bottom: 90px;
  }

  .list {
    .item {
      width: 293px;
      height: 640px;
      background: #fff;

      img {
        width: 100%;
      }

      .item-name {
        position: relative;
        margin-bottom: 50px;

        div {
          font-size: 26px;
          font-weight: bold;
          color: #FFFFFF;
          position: absolute;
          top: 30%;
          left: 10%;
        }
      }

      .children {
        text-align: left;
        padding: 0 30px;

        .children-title {
          font-size: 20px;
          font-weight: 500;
          color: #000000;
          line-height: 26px;
          margin-bottom: 10px;
        }

        .children-content {
          font-size: 16px;
          font-weight: 500;
          color: #666666;
          line-height: 26px;
          margin-bottom: 50px;
        }

        .mb30 {
          margin-bottom: 30px;
        }
      }

      &:hover {
        cursor: pointer;
        position: relative;
        top: -20px;
      }
    }
  }

}
</style>
