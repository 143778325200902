<template>
  <div class="container">
    <div class="title">系统价值</div>
      <div class="modele_row flex flex-between">
        <div v-for="(item,index) in moduleList" class="module_box">
          <div
              class="pic"
              :class="'pic' + index"
          >
            <div class="pic_title">{{ item.title }}<br/>{{ item.title2 }}</div>
            <div v-for="desc in item.descs" class="desc">{{ desc }}</div>
          </div>
          <div class="a1">
            <img :src="item.imgUrl"/>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      moduleList: [
        {
          title: "有效市场和有为政策的最佳结合点",
          title2: "",
          imgUrl:
              require("@/assets/system-value/item1.png"),
          imgShadowUrl:
              "https://imagepub.chinau8.cn/tianhua_jinhua/mj-website/module1_shadow.png",
          descs: [
            "多维应用服务体系，助推经济运行状况的评价+治理+服务闭环生态",
            "弥补市场在部分要素领域调节功能缺位的现状",
          ],
        },
        {
          title: "导向性科学可操作性强的评价体系",
          title2: "",
          imgUrl:
              require("@/assets/system-value/item2.png"),
          imgShadowUrl:
              "https://imagepub.chinau8.cn/tianhua_jinhua/mj-website/module2_shadow.png",
          descs: [
            "构建导向聚焦、全流程可视化的综合平台作为效益评价的载体支撑，系统化解决用户工作痛点",
          ],
        },
        {
          title: "助推产业治理创新优化",
          title2: "",
          imgUrl:
              require("@/assets/system-value/item3.png"),
          imgShadowUrl:
              "https://imagepub.chinau8.cn/tianhua_jinhua/mj-website/module3_shadow.png",
          descs: [
            "准确安全的数据，运用于各项创新性工作举措，有效盘活低效用地，助推区域经济稳健发展",
          ],
        },
        {
          title: "结果赋能区域高质发展",
          title2: "",
          imgUrl:
              require("@/assets/system-value/item4.png"),
          imgShadowUrl:
              "https://imagepub.chinau8.cn/tianhua_jinhua/mj-website/module6_shadow.png",
          descs: [
            "灵活配置，自动化流程，最大限度满足精准施策",
            "定制设置与二次开发相互独立，方便管理维护",
          ],
        },
      ],
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 1920px;
  height: 598px;
  padding: 100px 190px;
  box-sizing: border-box;
  background: #fff;

  .title {
    font-size: 52px;
    font-weight: bold;
    line-height: 61px;
    margin-bottom: 70px;
    text-align: center;
    color: #333333;
  }


  .modele_row {
    box-sizing: border-box;

    .module_box {
      width: 373px;
      height: 250px;
      background: linear-gradient(
              225deg,
              rgba(0, 0, 0, 0) 0%,
              rgba(255, 255, 255, 0.08) 100%
      );
      opacity: 1;
      position: relative;
      margin-bottom: 31px;

      img {
        width: 373px;
        height: 250px;
      }

      .pic {
        position: absolute;
        width: 373px;
        height: 250px;
        background: rgba(6, 107, 241);
        background-size: 100% 100%;
        box-sizing: border-box;
        padding:30px 12px 20px;
        font-size: 20px;
        font-weight: 300;
        color: #ffffff;
        text-align: left;

        .pic_title {
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 1px;
          color: #ffffff;
          line-height: 30px;
          margin-bottom: 15px;
          text-align: left;
          text-indent: 0;
        }

        .desc {
          font-size: 20px;
          font-weight: 400;
          color: #fff;
          line-height: 34px;
          text-indent: 2em;
        }
      }


      .a1 {
        position: absolute;
        top: 0;
        width: 373px;
        height: 250px;
        overflow: hidden;
        transition: all 0.3s;
      }

      .a1:hover {
        // transform: translateY(-6px);
        box-shadow: 0 26px 40px -24px rgba(0, 36, 100, 0.5);
        opacity: 0;
        // animation: move01 2s linear forwards;
      }

      @keyframes move01 {
        1% {
          opacity: 1;
        }
        25% {
          transform: rotateY(0);
        }
        62% {
          opacity: 1;
        }
        62.5% {
          transform: rotateY(90deg);
          opacity: 0;
        }
        100% {
          opacity: 0;
        }
      }
    }
  }

  .module_box:hover .pic {
    // transform: translateY(-6px);
    box-shadow: 0 26px 40px -24px rgba(0, 36, 100, 0.5);
    opacity: 1;
    //animation: move02 1s ease-out backwards;
    @keyframes move02 {
      48% {
        opacity: 0;
      }
      49% {
        opacity: 1;
      }
      50% {
        transform: rotateY(90deg);
      }
      100% {
        transform: rotateY(18deg);
        opacity: 1;
      }
    }
  }

}
</style>
