<template>
  <div class="container">

  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 1920px;
  height: 724px;
  //padding: 58px 210px;
  //box-sizing: border-box;
  background: url("http://imagepub.chinau8.com/tianhua_jinhua/sysyrm-dec2.png") no-repeat;
  background-size: 100% 100%;

  .title {
    font-size: 52px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 61px;
    margin-bottom: 30px;
  }

  .list {
    .item {
      width: 720px;
      text-align: left;
      margin-bottom: 10px;

      .item-title {
        font-size: 26px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 70px;

        &::before {
          display: inline-block;
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #FFFFFF;
          position: relative;
          top: -4px;
          right: 10px;
        }
      }

      .item-content {
        font-size: 20px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 40px;
        position: relative;
        left: -10px;
      }
    }
  }
}
</style>
