<template>
  <div class="">
    <!--头部banner部分-->
    <div class="banner">
<!--      <swiper-->
<!--          :options="swiperOption"-->
<!--          ref="mySwiper"-->
<!--          style="min-width: 1200px"-->
<!--          class="swiper_content"-->
<!--      >-->
<!--        <swiper-slide-->
<!--            class="swiper-slide swiper-container-3d"-->
<!--            v-for="(banner, index) in bannerList"-->
<!--            :key="index"-->
<!--        >-->
          <img class="banner-img" src="http://imagepub.chinau8.com/tianhua_jinhua/mj-website/banner1.png"/>
          <template>
            <div class="title1 a-bounceinT">
              全面深化经济高质量发展
            </div>
            <div class="title2 a-bounceinL">建立动态综合评价机制 助推亩均效益提档升级</div>
          </template>
<!--        </swiper-slide>-->
<!--        <div class="swiper-pagination" slot="pagination"></div>-->
<!--      </swiper>-->
    </div>
    <!--    更领先更专业部分-->
<!--    <Number></Number>-->
    <!--    系统优势-->
    <ProductAdvantage></ProductAdvantage>
    <!--  业务痛点-->
<!--    <BusinessSpot></BusinessSpot>-->
    <Process></Process>
    <!--    系统描述-->
    <SystemDesc></SystemDesc>
    <!--    系统价值-->
    <SystemValue></SystemValue>
    <!--    服务-->
    <!--    <Server></Server>-->
  </div>
</template>

<script>
import Number from "@/views/business-a/page-a/components/number.vue";
import ProductAdvantage from "@/views/business-a/page-a/components/product-advantage.vue";
import BusinessSpot from "@/views/business-a/page-a/components/business-spot.vue";
import Process from "@/views/business-a/page-a/components/process.vue"
import Server from "@/views/business-a/page-a/components/server.vue";
import SystemDesc from "@/views/business-a/page-a/components/system-desc.vue";
import SystemValue from "@/views/business-a/page-a/components/system-value.vue";

export default {
  name: "home",
  components: {
    Number,
    ProductAdvantage,
    Server,
    BusinessSpot,
    Process,
    SystemDesc,
    SystemValue
  },
  computed: {},
  data() {
    return {
      bannerList: [
        "http://imagepub.chinau8.com/tianhua_jinhua/mj-website/banner1.png",
        // "https://imagepub.chinau8.cn/tianhua_jinhua/mj-website/banner2.png",
      ],
      swiperOption: {
        loop: true,
        speed: 500,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        // autoplay: {
        //   delay: 4000,
        //   disableOnInteraction: false,
        // },
        autoplay: false,
        pagination: {
          el: ".swiper-pagination", //与slot="pagination"处 class 一致
          clickable: true, //轮播按钮支持点击
        },
      },
    };
  },
  created() {
  },
  destroyed() {
  },
  mounted() {
  },
  methods: {},
  beforeDestroy() {
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("firstTrue");
    next();
    // 离开当前组件时候触发
  },
};
</script>
<style lang="scss" scoped>
.swiper_content {
  z-index: 20;
  overflow: visible;
}

::v-deep .swiper-pagination .swiper-pagination-bullet {
  width: 0.26rem;
  height: 0.13rem;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0.3rem;
  margin-left: 14px;
}

/*显示当前活动块的图片*/
::v-deep .swiper-pagination .swiper-pagination-bullet-active {
  background: rgba(255, 255, 255, 1);
  border-radius: 0.3rem;
}

::v-deep .swiper-pagination {
  bottom: 0.1rem;
  left: 5rem;
}

.banner {
  width: 1920px;
  height: 1080px;
  background: #fff;
  position: relative;

  .banner-img {
    width: 1920px;
    height: 1080px;
    z-index: -99;
  }

  .a-bounceinL {
    animation: bounceinL 2s ease-out backwards;
    animation-delay: 1s;
  }

  .a-bounceinR {
    animation: bounceinR 2s ease-out backwards;
    animation-delay: 4s;
  }

  .title1 {
    width: 900px;
    position: absolute;
    top: 320px;
    left: 12%;
    font-size: 70px;
    font-weight: bold;
    color: #fff;
    line-height: 98px;
    text-align: left;
  }

  .title2 {
    width: 1200px;
    position: absolute;
    top: 420px;
    left: 12%;
    font-size: 48px;
    color: #fff;
    line-height: 140px;
    text-align: left;
  }

  .title3 {
    width: 1316px;
    position: absolute;
    top: 450px;
    left: 50%;
    margin-left: -658px;
    z-index: 999;
    font-size: 64px;
    font-weight: bold;
    color: #fff;
    letter-spacing: 5px;
    line-height: 140px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
}
</style>
