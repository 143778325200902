<template>
  <div class="number-container">
    <div class="title">更专业才能更领先</div>
    <div class="line"></div>
    <div class="flex list-box flex-around">
      <section v-for="item in list" class="list">
        <div class="list-name">{{ item.name }}</div>
        <div class="list-number">{{ item.number }}</div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {name: "平台覆盖评价企业数", number: "5000+"},
        {name: "平台统计亩地总数", number: "60000+"}
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.number-container {
  width: 1920px;
  height: 460px;
  background: url("~@/assets/index/number-bg.png") no-repeat;
  box-sizing: border-box;
  background-size: contain;
  padding: 64px 282px;
  position: relative;

  .title {
    font-size: 42px;
    font-weight: bold;
    color: #333333;
    line-height: 49px;
    text-align: center;
    margin-bottom: 88px;
  }

  .line {
    width: 2px;
    height: 159px;
    background: #90BEFF;
    position: absolute;
    left: 50%;
    top: 37%;
  }

  .list-box {
    width: 100%;

    .list {
      .list-name {
        font-size: 24px;
        font-weight: 400;
        color: #666666;
        line-height: 32px;
        margin-bottom: 27px;
      }

      .list-number {
        font-size: 80px;
        font-weight: 800;
        color: #066BF1;
        line-height: 60px;
      }
    }
  }


}
</style>
