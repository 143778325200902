<template>
  <div class="product-advantage-container">
    <div class="title">系统优势</div>
    <img class="bg" src="http://imagepub.chinau8.com/tianhua_jinhua/system-advantage.png" alt=""/>
    <div class="flex flex-align word" v-for="(item,index) in list1"
         :class="`${startAnimation ? 'a-fadeinL' : ''} ${'word' + (index + 1)}`" v-if="startAnimation">
      <img src="~@/assets/index/product-icon.png" alt=""/>
      <span>{{ item }}</span>
    </div>
    <div class="flex flex-align word" v-for="(item,index) in list2"
         :class="`${startAnimation ? 'a-fadeinR' : ''} ${'word' + (index + 4)}`" v-if="startAnimation">
      <img src="~@/assets/index/product-icon.png" alt=""/>
      <span>{{ item }}</span>
    </div>
    <div class="flex flex-align word" v-for="(item,index) in list3"
         :class="`${startAnimation ? 'a-fadeinB' : ''} ${'word' + (index + 7)}`" v-if="startAnimation">
      <img src="~@/assets/index/product-icon.png" alt=""/>
      <span>{{ item }}</span>
    </div>
<!--    <div class="bottom-box">-->
<!--      <img src="~@/assets/index/product-bottom.png" alt=""/>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      scroll: 0,
      startAnimation: false, //文字是否出现
      list1: [
        '多跨协同架构', '数据清洗归集',
      ],
      list2: [
        '企业体检报告', '分类模型预演',
      ],
      list3: [
        '分类专题报告', '综合分析统计', '工业用地监测',
      ],
    }
  },
  methods: {
    handleScroll() {
      //或者使用document.querySelector('.class或者#id').scrollTop
      this.scroll =
          document.documentElement.scrollTop || document.body.scrollTop;
      this.startAnimation = this.scroll > 900;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
}
</script>

<style lang="scss" scoped>
.product-advantage-container {
  width: 1920px;
  height: 1080px;
  background: url("http://imagepub.chinau8.com/tianhua_jinhua/mj-website/product-bg.png") no-repeat;
  box-sizing: border-box;
  background-size: 100% 100%;
  padding: 64px 176px;
  position: relative;

  .title {
    font-size: 42px;
    font-weight: bold;
    color: #333333;
    line-height: 49px;
    text-align: center;
    margin-bottom: 124px;
  }
  .bg{
    width: 1058px;
  }

  .word {
    img {
      width: 36px;
      height: 36px;
      margin-right: 16px;
    }

    span {
      font-size: 26px;
      font-weight: 500;
      color: #333333;
      line-height: 30px;
    }
  }

  .word1 {
    position: absolute;
    left: 9%;
    top: 45%;
  }

  .word2 {
    position: absolute;
    left: 9%;
    top: 52%;
  }

  .word3 {
    position: absolute;
    left: 9%;
    top: 49%;
  }

  .word4 {
    position: absolute;
    left: 80%;
    top: 45%;
  }

  .word5 {
    position: absolute;
    left: 80%;
    top: 52%;
  }

  .word6 {
    position: absolute;
    left: 80%;
    top: 49%;
  }

  .word7 {
    position: absolute;
    left: 29%;
    top: 85%;
  }

  .word8 {
    position: absolute;
    left: 44%;
    top: 85%;
  }

  .word9 {
    position: absolute;
    left: 58%;
    top: 85%;
  }

  .a-fadeinL {
    animation: fadeinL 1.5s ease-out backwards;
  }

  .a-fadeinR {
    animation: fadeinR 1.5s ease-out backwards;
  }

  .a-fadeinB {
    animation: fadeinB 1.5s ease-out backwards;
  }

  .bottom-box {
    position: relative;
    bottom: -250px;
  }
}
</style>
