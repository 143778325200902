<template>
  <div class="container">
    <div class="title">业务痛点</div>
    <div class="box">
      <div class="list">
        <div class="item flex flex-align-center" v-for="item in list">
          <div class="item-left">{{ item.code }}</div>
          <div class="flex flex-direction-column item-right">
            <div class="item-title">
              {{ item.title }}
            </div>
            <div class="item-content">
              {{ item.content }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          code: "01",
          title: "评价数据获取难",
          content: "各部门多维度的指标数据，共享、采集存在较大阻碍，易产生失真风险，数据整理汇交工作繁琐"
        },
        {
          code: "02",
          title: "评价范围日趋扩大",
          content: "随着高质发展的探索深化，评价主体在不断扩大，对应的经济运行指标，运用于工作中也待持续优化"
        },
        {
          code: "03",
          title: "评价导向更加聚焦",
          content: "随着改革走向纵深，资源集约利用、精准治理服务等创新举措，深化应用于工作中，日趋迫切"
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 1920px;
  height: 909px;
  box-sizing: border-box;
  background: url("~@/assets/homepage/business-spot.png") no-repeat;
  background-size: 100% 100%;
  padding: 130px 210px;
  position: relative;

  .title {
    font-size: 52px;
    font-weight: bold;
    color: #333333;
    line-height: 61px;
    margin-bottom: 32px;
    text-align: left;
  }

  .box {
    width: 1710px;
    height: 668px;
    background: #fff;
    padding: 83px 90px;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;

    .list {
      .item {
        margin-bottom: 50px;

        .item-left {
          font-size: 108px;
          font-weight: bold;
          color: #333333;
          line-height: 127px;
          margin-right: 56px;
        }

        &:hover .item-left {
          transform: translateY(-10px);
          transition: transform 0.3s;
          text-shadow: 10px 10px 6px rgb(51, 51, 51, 0.5);
          -webkit-background-clip: text;
        }

        .item-right {
          align-items: baseline;

          .item-title {
            font-size: 32px;
            font-weight: 500;
            color: #333333;
            line-height: 38px;
            margin-bottom: 7px;
          }

          .item-content {
            font-size: 24px;
            font-weight: 400;
            color: #333333;
            line-height: 28px;
          }
        }
      }
    }
  }
}
</style>
